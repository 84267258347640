/**
 * If a policy name is formatted using the stacklet
 * naming convention, i.e. <cloudprovider>-<resource>-<policy-name>
 * truncate to only the policy name for display
 *
 * @param {Policy} policy
 * @returns formatted policy name
 */
export const formatPolicyName = (policy) => {
  const { name, resource } = policy;
  try {
    // Assumes resource is in the format "provider.resource"
    // e.g. "aws.ebs-snapshot"
    const parsedResource = resource.replace(/\./g, "-");
    if (name.startsWith(parsedResource)) {
      return name.replace(parsedResource, "").substring(1);
    }
  } catch {
    return policy.name;
  }
  return policy.name;
};
