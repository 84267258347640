<template>
  <div class="policies-wrapper">
    <div class="st-view-wrapper">
      <div class="st-view-header-wrapper">
        <div class="st-view-header-content">
          <div class="container-fluid g-0">
            <div class="policies-header">
              <button
                tabindex="0"
                class="filter-button"
                @click="state.visibleLeft = true"
                @keypress="state.visibleLeft = true"
              >
                Filter ( {{ state.itemsSelectedCount }} )
              </button>
              <Sidebar v-model:visible="state.visibleLeft" class="custom">
                <PoliciesFilter
                  class="policies-filter"
                  :filters="state.filtersSelected"
                  @filter="handleFilter"
                />
              </Sidebar>

              <SearchBar class="searchbar flex-grow-1" @search="handleSearch" />
              <div class="view-icons">
                <a
                  id="cardViewIcon"
                  aria-label="card view"
                  role="button"
                  class="st-icon st-icon-tile-view"
                  tabindex="0"
                  :class="{ active: !route.query.tableView }"
                  @click="cardView"
                  @keypress="cardView"
                >
                </a>
                <a
                  id="listViewIcon"
                  aria-label="table view"
                  role="button"
                  class="st-icon st-icon-list-view"
                  tabindex="0"
                  :class="{ active: route.query.tableView === 'true' }"
                  @click="listView"
                  @keypress="listView"
                >
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Spinner v-if="loading" class="centered" />
      <div v-else-if="error">Error: {{ error.message }}</div>
      <div v-else-if="hasPolicies" class="st-view-body-wrapper">
        <div class="st-view-body-content">
          <div class="row st-view-features-bar">
            <div class="right-item col-sm row row-cols-auto g-0">
              <PropertiesDropdown @property-updated="handlePropertySelected" />
            </div>
          </div>

          <div class="st-tab-selection">
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a
                  id="one_day"
                  role="button"
                  class="st-tab-link"
                  tabindex="0"
                  :class="{ active: state.period === TimePeriod.daily }"
                  @click="updatePeriod(TimePeriod.daily)"
                  @keypress="updatePeriod(TimePeriod.daily)"
                  >1D</a
                >
              </li>
              <li class="nav-item">
                <a
                  id="one_week"
                  role="button"
                  class="st-tab-link"
                  tabindex="0"
                  :class="{ active: state.period === TimePeriod.weekly }"
                  @click="updatePeriod(TimePeriod.weekly)"
                  @keypress="updatePeriod(TimePeriod.weekly)"
                  >1W</a
                >
              </li>
              <li class="nav-item">
                <a
                  id="one_month"
                  role="button"
                  class="st-tab-link"
                  tabindex="0"
                  :class="{ active: state.period === TimePeriod.monthly }"
                  @click="updatePeriod(TimePeriod.monthly)"
                  @keypress="updatePeriod(TimePeriod.monthly)"
                  >1M</a
                >
              </li>
            </ul>
          </div>

          <div v-if="route.query.tableView">
            <PolicyTable
              :policies="policies"
              :property-selected="state.property"
              :period="state.period"
            />
          </div>
          <div v-else>
            <PolicyCardGrid
              :policies="policies"
              :property-selected="state.property"
              :period="state.period"
            />
          </div>
          <PaginationApi
            :page-size="state.pageSize"
            :handle-page-size-change="handlePageSizeChange"
            :has-previous-page="hasPreviousPage"
            :has-next-page="hasNextPage"
            :load-more="loadMore"
          />
        </div>
      </div>
      <div v-else class="st-view-body-wrapper">
        <div class="st-view-body-content mt-5">No policies found</div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useQuery, useResult } from "@vue/apollo-composable";
import PaginationApi from "@/components/PaginationApi";
import PolicyCardGrid from "@/components/PolicyCardGrid";
import PolicyTable from "@/components/PolicyTable";
import PropertiesDropdown from "@/components/PropertiesDropdown";
import SearchBar from "@/components/SearchBar";
import Spinner from "@/components/Spinner";
import PoliciesFilter from "@/components/PoliciesFilter.vue";
import useLoadMore from "@/composables/useLoadMore";
import allPoliciesQuery from "@/graphql/allPolicies.query.gql";
import { TimePeriod } from "@/utils/enum.js";
import Sidebar from "primevue/sidebar";
export default {
  name: "Policies",
  components: {
    PolicyCardGrid,
    PolicyTable,
    SearchBar,
    Spinner,
    PropertiesDropdown,
    PaginationApi,
    PoliciesFilter,
    Sidebar,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      property: "resourceCount",
      pageSize: 12,
      period: TimePeriod.monthly,
      selectMode: null,
      visibleLeft: false,
      itemsSelectedCount: 0,
      filtersSelected: {
        compliances: [],
        categories: [],
        resources: [],
        selectProvider: null,
      },
    });

    const { result, loading, error, fetchMore, variables } = useQuery(
      allPoliciesQuery,
      {
        first: computed(() => state.pageSize),
        last: computed(() => state.pageSize),
        after: "",
        before: "",
        filter: "",
      },
      {
        debounce: 500,
        notifyOnNetworkStatusChange: true, //this is for loading in useQuery to work with fetchMore
      }
    );

    const policies = useResult(result, [], (data) =>
      data.policies.edges.map((edge) => edge.node)
    );

    const hasPolicies = computed(() => policies.value.length > 0);

    const pageInfo = useResult(
      result,
      { hasPreviousPage: false, hasNextPage: false },
      (data) => data.policies.pageInfo
    );

    const { hasPreviousPage, hasNextPage, loadMore } = useLoadMore(
      fetchMore,
      pageInfo
    );

    const handlePageSizeChange = (pageSize) => {
      if (pageSize) {
        state.pageSize = pageSize;
      }
    };

    const handleSearch = (searchTerm = "") => {
      variables.value = {
        filter: searchTerm,
      };
    };

    const handleFilter = (
      searchFilter = "",
      itemsSelectedCount,
      filtersSelected,
      isFilterQueryEmpty
    ) => {
      state.itemsSelectedCount = itemsSelectedCount;
      state.filtersSelected = filtersSelected;
      state.visibleLeft = false;
      if (isFilterQueryEmpty) {
        variables.value = {
          filter: "",
        };
        return;
      }
      variables.value = {
        filter: searchFilter,
      };
    };

    const handlePropertySelected = (property) => {
      state.property = property;
    };

    const cardView = () => {
      router.push({ query: {} });
    };

    const listView = () => {
      router.push({ query: { tableView: "true" } });
    };

    const updatePeriod = (period) => {
      state.period = period;
    };

    return {
      state,
      handleSearch,
      handleFilter,
      cardView,
      listView,
      route,
      loading,
      policies,
      hasPolicies,
      loadMore,
      error,
      handlePropertySelected,
      hasPreviousPage,
      hasNextPage,
      handlePageSizeChange,
      updatePeriod,
      TimePeriod,
    };
  },
};
</script>

<style lang="scss" scoped>
.policies-header {
  align-items: center;
  display: flex;
}

.filter-button {
  background-color: $st-orange;
  border: 0;
  border-radius: 2px;
  color: $white;
  font-size: 16px;
  height: 48px;
  margin-right: 20px;
  width: 94px;
}

.right-item {
  justify-content: flex-end;
  width: auto;
}

.view-icons {
  justify-content: center;
}

.policies-filter {
  padding-top: 40px;
}
@media screen and (max-width: 576px) {
  .right-item {
    justify-content: flex-start;
    margin-top: 20px;
    width: -moz-fill-available;
    width: -webkit-stretch;
  }
}

.st-tab-selection {
  display: flex;
  flex-direction: row-reverse;

  .nav-item > a {
    height: 30px;
    padding: 0;
    width: 80px;
  }
}

.st-icon.active {
  filter: $st-icon-orange;
}

.st-icon-tile-view {
  margin-right: 15px;
}

.right-item.header {
  align-items: center;
  display: flex;
}

.centered {
  left: 50%;
  position: fixed;
  top: 50%;
}

.back-button {
  padding: 16px 0 8px;
}

.p-button {
  background-color: $st-orange;
}
</style>
