export const instances = [
  {
    AmiLaunchIndex: 3,
    ImageId: "ami-07efac79022b86107",
    InstanceId: "i-011f9ef5ab6fd93d6",
    InstanceType: "t2.micro",
    KeyName: "first-vm",
    LaunchTime: "2020-10-28T20:02:24+00:00",
    Monitoring: {
      State: "disabled",
    },
    Placement: {
      AvailabilityZone: "us-east-2a",
      GroupName: "",
      Tenancy: "default",
    },
    PrivateDnsName: "ip-172-31-0-59.us-east-2.compute.internal",
    PrivateIpAddress: "172.31.0.59",
    ProductCodes: [],
    PublicDnsName: "",
    State: {
      Code: 16,
      Name: "running",
    },
    StateTransitionReason: "",
    SubnetId: "subnet-0d9eca9395e167c25",
    VpcId: "vpc-0c02ea1fffb823cc4",
    Architecture: "x86_64",
    BlockDeviceMappings: [
      {
        DeviceName: "/dev/sda1",
        Ebs: {
          AttachTime: "2020-10-28T20:02:25+00:00",
          DeleteOnTermination: true,
          Status: "attached",
          VolumeId: "vol-0a548c6ece3a7a83f",
        },
      },
    ],
    ClientToken: "",
    EbsOptimized: false,
    EnaSupport: true,
    Hypervisor: "xen",
    NetworkInterfaces: [
      {
        Attachment: {
          AttachTime: "2020-10-28T20:02:24+00:00",
          AttachmentId: "eni-attach-094f6550f71533f68",
          DeleteOnTermination: true,
          DeviceIndex: 0,
          Status: "attached",
        },
        Description: "Primary network interface",
        Groups: [
          {
            GroupName: "launch-wizard-4",
            GroupId: "sg-0c5bcfe09ef028af7",
          },
        ],
        Ipv6Addresses: [],
        MacAddress: "02:04:c9:3a:4c:0a",
        NetworkInterfaceId: "eni-05d439a991826a166",
        OwnerId: "456023024783",
        PrivateDnsName: "ip-172-31-0-59.us-east-2.compute.internal",
        PrivateIpAddress: "172.31.0.59",
        PrivateIpAddresses: [
          {
            Primary: true,
            PrivateDnsName: "ip-172-31-0-59.us-east-2.compute.internal",
            PrivateIpAddress: "172.31.0.59",
          },
        ],
        SourceDestCheck: true,
        Status: "in-use",
        SubnetId: "subnet-0d9eca9395e167c25",
        VpcId: "vpc-0c02ea1fffb823cc4",
        InterfaceType: "interface",
      },
    ],
    RootDeviceName: "/dev/sda1",
    RootDeviceType: "ebs",
    SecurityGroups: [
      {
        GroupName: "launch-wizard-4",
        GroupId: "sg-0c5bcfe09ef028af7",
      },
    ],
    SourceDestCheck: true,
    VirtualizationType: "hvm",
    CpuOptions: {
      CoreCount: 1,
      ThreadsPerCore: 1,
    },
    CapacityReservationSpecification: {
      CapacityReservationPreference: "open",
    },
    HibernationOptions: {
      Configured: false,
    },
    MetadataOptions: {
      State: "applied",
      HttpTokens: "optional",
      HttpPutResponseHopLimit: 1,
      HttpEndpoint: "enabled",
    },
  },
  {
    AmiLaunchIndex: 4,
    ImageId: "ami-07efac79022b86107",
    InstanceId: "i-0ab6d23ff5d747b16",
    InstanceType: "t2.micro",
    KeyName: "first-vm",
    LaunchTime: "2020-10-28T20:02:24+00:00",
    Monitoring: {
      State: "disabled",
    },
    Placement: {
      AvailabilityZone: "us-east-2a",
      GroupName: "",
      Tenancy: "default",
    },
    PrivateDnsName: "ip-172-31-0-222.us-east-2.compute.internal",
    PrivateIpAddress: "172.31.0.222",
    ProductCodes: [],
    PublicDnsName: "",
    State: {
      Code: 16,
      Name: "running",
    },
    StateTransitionReason: "",
    SubnetId: "subnet-0d9eca9395e167c25",
    VpcId: "vpc-0c02ea1fffb823cc4",
    Architecture: "x86_64",
    BlockDeviceMappings: [
      {
        DeviceName: "/dev/sda1",
        Ebs: {
          AttachTime: "2020-10-28T20:02:25+00:00",
          DeleteOnTermination: true,
          Status: "attached",
          VolumeId: "vol-0abf20c0fc4ebcf6f",
        },
      },
    ],
    ClientToken: "",
    EbsOptimized: false,
    EnaSupport: true,
    Hypervisor: "xen",
    NetworkInterfaces: [
      {
        Attachment: {
          AttachTime: "2020-10-28T20:02:24+00:00",
          AttachmentId: "eni-attach-0db43028d57fae3ba",
          DeleteOnTermination: true,
          DeviceIndex: 0,
          Status: "attached",
        },
        Description: "Primary network interface",
        Groups: [
          {
            GroupName: "launch-wizard-4",
            GroupId: "sg-0c5bcfe09ef028af7",
          },
        ],
        Ipv6Addresses: [],
        MacAddress: "02:a2:9e:79:0e:8a",
        NetworkInterfaceId: "eni-02434db5ff5a1f6aa",
        OwnerId: "456023024783",
        PrivateDnsName: "ip-172-31-0-222.us-east-2.compute.internal",
        PrivateIpAddress: "172.31.0.222",
        PrivateIpAddresses: [
          {
            Primary: true,
            PrivateDnsName: "ip-172-31-0-222.us-east-2.compute.internal",
            PrivateIpAddress: "172.31.0.222",
          },
        ],
        SourceDestCheck: true,
        Status: "in-use",
        SubnetId: "subnet-0d9eca9395e167c25",
        VpcId: "vpc-0c02ea1fffb823cc4",
        InterfaceType: "interface",
      },
    ],
    RootDeviceName: "/dev/sda1",
    RootDeviceType: "ebs",
    SecurityGroups: [
      {
        GroupName: "launch-wizard-4",
        GroupId: "sg-0c5bcfe09ef028af7",
      },
    ],
    SourceDestCheck: true,
    VirtualizationType: "hvm",
    CpuOptions: {
      CoreCount: 1,
      ThreadsPerCore: 1,
    },
    CapacityReservationSpecification: {
      CapacityReservationPreference: "open",
    },
    HibernationOptions: {
      Configured: false,
    },
    MetadataOptions: {
      State: "applied",
      HttpTokens: "optional",
      HttpPutResponseHopLimit: 1,
      HttpEndpoint: "enabled",
    },
  },
  {
    AmiLaunchIndex: 1,
    ImageId: "ami-07efac79022b86107",
    InstanceId: "i-013f2edf18718f375",
    InstanceType: "t2.micro",
    KeyName: "first-vm",
    LaunchTime: "2020-10-28T20:02:24+00:00",
    Monitoring: {
      State: "disabled",
    },
    Placement: {
      AvailabilityZone: "us-east-2a",
      GroupName: "",
      Tenancy: "default",
    },
    PrivateDnsName: "ip-172-31-0-168.us-east-2.compute.internal",
    PrivateIpAddress: "172.31.0.168",
    ProductCodes: [],
    PublicDnsName: "",
    State: {
      Code: 16,
      Name: "running",
    },
    StateTransitionReason: "",
    SubnetId: "subnet-0d9eca9395e167c25",
    VpcId: "vpc-0c02ea1fffb823cc4",
    Architecture: "x86_64",
    BlockDeviceMappings: [
      {
        DeviceName: "/dev/sda1",
        Ebs: {
          AttachTime: "2020-10-28T20:02:25+00:00",
          DeleteOnTermination: true,
          Status: "attached",
          VolumeId: "vol-0708e1431108e92e2",
        },
      },
    ],
    ClientToken: "",
    EbsOptimized: false,
    EnaSupport: true,
    Hypervisor: "xen",
    NetworkInterfaces: [
      {
        Attachment: {
          AttachTime: "2020-10-28T20:02:24+00:00",
          AttachmentId: "eni-attach-016d883e97e1fffae",
          DeleteOnTermination: true,
          DeviceIndex: 0,
          Status: "attached",
        },
        Description: "Primary network interface",
        Groups: [
          {
            GroupName: "launch-wizard-4",
            GroupId: "sg-0c5bcfe09ef028af7",
          },
        ],
        Ipv6Addresses: [],
        MacAddress: "02:4e:57:50:59:58",
        NetworkInterfaceId: "eni-0e577705724dbb94d",
        OwnerId: "456023024783",
        PrivateDnsName: "ip-172-31-0-168.us-east-2.compute.internal",
        PrivateIpAddress: "172.31.0.168",
        PrivateIpAddresses: [
          {
            Primary: true,
            PrivateDnsName: "ip-172-31-0-168.us-east-2.compute.internal",
            PrivateIpAddress: "172.31.0.168",
          },
        ],
        SourceDestCheck: true,
        Status: "in-use",
        SubnetId: "subnet-0d9eca9395e167c25",
        VpcId: "vpc-0c02ea1fffb823cc4",
        InterfaceType: "interface",
      },
    ],
    RootDeviceName: "/dev/sda1",
    RootDeviceType: "ebs",
    SecurityGroups: [
      {
        GroupName: "launch-wizard-4",
        GroupId: "sg-0c5bcfe09ef028af7",
      },
    ],
    SourceDestCheck: true,
    VirtualizationType: "hvm",
    CpuOptions: {
      CoreCount: 1,
      ThreadsPerCore: 1,
    },
    CapacityReservationSpecification: {
      CapacityReservationPreference: "open",
    },
    HibernationOptions: {
      Configured: false,
    },
    MetadataOptions: {
      State: "applied",
      HttpTokens: "optional",
      HttpPutResponseHopLimit: 1,
      HttpEndpoint: "enabled",
    },
  },
  {
    AmiLaunchIndex: 2,
    ImageId: "ami-07efac79022b86107",
    InstanceId: "i-051603f57455eb8cd",
    InstanceType: "t2.micro",
    KeyName: "first-vm",
    LaunchTime: "2020-10-28T20:02:24+00:00",
    Monitoring: {
      State: "disabled",
    },
    Placement: {
      AvailabilityZone: "us-east-2a",
      GroupName: "",
      Tenancy: "default",
    },
    PrivateDnsName: "ip-172-31-0-138.us-east-2.compute.internal",
    PrivateIpAddress: "172.31.0.138",
    ProductCodes: [],
    PublicDnsName: "",
    State: {
      Code: 16,
      Name: "running",
    },
    StateTransitionReason: "",
    SubnetId: "subnet-0d9eca9395e167c25",
    VpcId: "vpc-0c02ea1fffb823cc4",
    Architecture: "x86_64",
    BlockDeviceMappings: [
      {
        DeviceName: "/dev/sda1",
        Ebs: {
          AttachTime: "2020-10-28T20:02:25+00:00",
          DeleteOnTermination: true,
          Status: "attached",
          VolumeId: "vol-002a9dc73b80d96d8",
        },
      },
    ],
    ClientToken: "",
    EbsOptimized: false,
    EnaSupport: true,
    Hypervisor: "xen",
    NetworkInterfaces: [
      {
        Attachment: {
          AttachTime: "2020-10-28T20:02:24+00:00",
          AttachmentId: "eni-attach-00f3a7d5452f8f853",
          DeleteOnTermination: true,
          DeviceIndex: 0,
          Status: "attached",
        },
        Description: "Primary network interface",
        Groups: [
          {
            GroupName: "launch-wizard-4",
            GroupId: "sg-0c5bcfe09ef028af7",
          },
        ],
        Ipv6Addresses: [],
        MacAddress: "02:9a:76:cc:93:72",
        NetworkInterfaceId: "eni-0c96925700da13b55",
        OwnerId: "456023024783",
        PrivateDnsName: "ip-172-31-0-138.us-east-2.compute.internal",
        PrivateIpAddress: "172.31.0.138",
        PrivateIpAddresses: [
          {
            Primary: true,
            PrivateDnsName: "ip-172-31-0-138.us-east-2.compute.internal",
            PrivateIpAddress: "172.31.0.138",
          },
        ],
        SourceDestCheck: true,
        Status: "in-use",
        SubnetId: "subnet-0d9eca9395e167c25",
        VpcId: "vpc-0c02ea1fffb823cc4",
        InterfaceType: "interface",
      },
    ],
    RootDeviceName: "/dev/sda1",
    RootDeviceType: "ebs",
    SecurityGroups: [
      {
        GroupName: "launch-wizard-4",
        GroupId: "sg-0c5bcfe09ef028af7",
      },
    ],
    SourceDestCheck: true,
    VirtualizationType: "hvm",
    CpuOptions: {
      CoreCount: 1,
      ThreadsPerCore: 1,
    },
    CapacityReservationSpecification: {
      CapacityReservationPreference: "open",
    },
    HibernationOptions: {
      Configured: false,
    },
    MetadataOptions: {
      State: "applied",
      HttpTokens: "optional",
      HttpPutResponseHopLimit: 1,
      HttpEndpoint: "enabled",
    },
  },
  {
    AmiLaunchIndex: 0,
    ImageId: "ami-07efac79022b86107",
    InstanceId: "i-0de2677f9e64494c1",
    InstanceType: "t2.micro",
    KeyName: "first-vm",
    LaunchTime: "2020-10-28T20:02:24+00:00",
    Monitoring: {
      State: "disabled",
    },
    Placement: {
      AvailabilityZone: "us-east-2a",
      GroupName: "",
      Tenancy: "default",
    },
    PrivateDnsName: "ip-172-31-0-244.us-east-2.compute.internal",
    PrivateIpAddress: "172.31.0.244",
    ProductCodes: [],
    PublicDnsName: "",
    State: {
      Code: 16,
      Name: "running",
    },
    StateTransitionReason: "",
    SubnetId: "subnet-0d9eca9395e167c25",
    VpcId: "vpc-0c02ea1fffb823cc4",
    Architecture: "x86_64",
    BlockDeviceMappings: [
      {
        DeviceName: "/dev/sda1",
        Ebs: {
          AttachTime: "2020-10-28T20:02:25+00:00",
          DeleteOnTermination: true,
          Status: "attached",
          VolumeId: "vol-06bf78152a2c1f36a",
        },
      },
    ],
    ClientToken: "",
    EbsOptimized: false,
    EnaSupport: true,
    Hypervisor: "xen",
    NetworkInterfaces: [
      {
        Attachment: {
          AttachTime: "2020-10-28T20:02:24+00:00",
          AttachmentId: "eni-attach-0635ffe80974b006d",
          DeleteOnTermination: true,
          DeviceIndex: 0,
          Status: "attached",
        },
        Description: "Primary network interface",
        Groups: [
          {
            GroupName: "launch-wizard-4",
            GroupId: "sg-0c5bcfe09ef028af7",
          },
        ],
        Ipv6Addresses: [],
        MacAddress: "02:51:fb:73:b6:2a",
        NetworkInterfaceId: "eni-07c50940a18a7d817",
        OwnerId: "456023024783",
        PrivateDnsName: "ip-172-31-0-244.us-east-2.compute.internal",
        PrivateIpAddress: "172.31.0.244",
        PrivateIpAddresses: [
          {
            Primary: true,
            PrivateDnsName: "ip-172-31-0-244.us-east-2.compute.internal",
            PrivateIpAddress: "172.31.0.244",
          },
        ],
        SourceDestCheck: true,
        Status: "in-use",
        SubnetId: "subnet-0d9eca9395e167c25",
        VpcId: "vpc-0c02ea1fffb823cc4",
        InterfaceType: "interface",
      },
    ],
    RootDeviceName: "/dev/sda1",
    RootDeviceType: "ebs",
    SecurityGroups: [
      {
        GroupName: "launch-wizard-4",
        GroupId: "sg-0c5bcfe09ef028af7",
      },
    ],
    SourceDestCheck: true,
    VirtualizationType: "hvm",
    CpuOptions: {
      CoreCount: 1,
      ThreadsPerCore: 1,
    },
    CapacityReservationSpecification: {
      CapacityReservationPreference: "open",
    },
    HibernationOptions: {
      Configured: false,
    },
    MetadataOptions: {
      State: "applied",
      HttpTokens: "optional",
      HttpPutResponseHopLimit: 1,
      HttpEndpoint: "enabled",
    },
  },
  {
    AmiLaunchIndex: 0,
    ImageId: "ami-0885b1f6bd170450c",
    InstanceId: "i-07f09bcc2aa483036",
    InstanceType: "t3.medium",
    LaunchTime: "2020-10-28T16:30:41+00:00",
    Monitoring: {
      State: "disabled",
    },
    Placement: {
      AvailabilityZone: "us-east-1d",
      GroupName: "",
      Tenancy: "default",
    },
    PrivateDnsName: "ip-172-31-89-103.ec2.internal",
    PrivateIpAddress: "172.31.89.103",
    ProductCodes: [],
    PublicDnsName: "ec2-52-87-182-59.compute-1.amazonaws.com",
    PublicIpAddress: "52.87.182.59",
    State: {
      Code: 16,
      Name: "running",
    },
    StateTransitionReason: "",
    SubnetId: "subnet-036a8eff4cd548717",
    VpcId: "vpc-0fba96396a307f81e",
    Architecture: "x86_64",
    BlockDeviceMappings: [
      {
        DeviceName: "/dev/sda1",
        Ebs: {
          AttachTime: "2020-10-28T16:30:43+00:00",
          DeleteOnTermination: true,
          Status: "attached",
          VolumeId: "vol-03bc185a1442e15fb",
        },
      },
    ],
    ClientToken: "cc55d5c0-303a-fb80-67d3-ccc5fc51be56",
    EbsOptimized: false,
    EnaSupport: true,
    Hypervisor: "xen",
    NetworkInterfaces: [
      {
        Association: {
          IpOwnerId: "amazon",
          PublicDnsName: "ec2-52-87-182-59.compute-1.amazonaws.com",
          PublicIp: "52.87.182.59",
        },
        Attachment: {
          AttachTime: "2020-10-28T16:30:41+00:00",
          AttachmentId: "eni-attach-00adfa12adda0a85c",
          DeleteOnTermination: true,
          DeviceIndex: 0,
          Status: "attached",
        },
        Description: "",
        Groups: [
          {
            GroupName: "default",
            GroupId: "sg-06fb2a2a103915ad4",
          },
        ],
        Ipv6Addresses: [],
        MacAddress: "12:00:c1:37:46:ff",
        NetworkInterfaceId: "eni-041636dd2cc5d1a2f",
        OwnerId: "662108712480",
        PrivateDnsName: "ip-172-31-89-103.ec2.internal",
        PrivateIpAddress: "172.31.89.103",
        PrivateIpAddresses: [
          {
            Association: {
              IpOwnerId: "amazon",
              PublicDnsName: "ec2-52-87-182-59.compute-1.amazonaws.com",
              PublicIp: "52.87.182.59",
            },
            Primary: true,
            PrivateDnsName: "ip-172-31-89-103.ec2.internal",
            PrivateIpAddress: "172.31.89.103",
          },
        ],
        SourceDestCheck: true,
        Status: "in-use",
        SubnetId: "subnet-036a8eff4cd548717",
        VpcId: "vpc-0fba96396a307f81e",
        InterfaceType: "interface",
      },
    ],
    RootDeviceName: "/dev/sda1",
    RootDeviceType: "ebs",
    SecurityGroups: [
      {
        GroupName: "default",
        GroupId: "sg-06fb2a2a103915ad4",
      },
    ],
    SourceDestCheck: true,
    Tags: [
      {
        Key: "aws:ec2launchtemplate:version",
        Value: "1",
      },
      {
        Key: "aws:autoscaling:groupName",
        Value: "tf-asg-20201028163034137800000003",
      },
      {
        Key: "aws:ec2launchtemplate:id",
        Value: "lt-0ab53d9b5207ff8c0",
      },
      {
        Key: "App",
        Value: "Testing",
      },
    ],
    VirtualizationType: "hvm",
    CpuOptions: {
      CoreCount: 1,
      ThreadsPerCore: 2,
    },
    CapacityReservationSpecification: {
      CapacityReservationPreference: "open",
    },
    HibernationOptions: {
      Configured: false,
    },
    MetadataOptions: {
      State: "applied",
      HttpTokens: "optional",
      HttpPutResponseHopLimit: 1,
      HttpEndpoint: "enabled",
    },
  },
  {
    AmiLaunchIndex: 0,
    ImageId: "ami-0ea142bd244023692",
    InstanceId: "i-0244552854b6ee73c",
    InstanceType: "m6g.xlarge",
    KeyName: "kapil-sandbox-us-east-1",
    LaunchTime: "2020-10-25T11:23:10+00:00",
    Monitoring: {
      State: "disabled",
    },
    Placement: {
      AvailabilityZone: "us-east-1b",
      GroupName: "",
      Tenancy: "default",
    },
    PrivateDnsName: "ip-172-31-37-171.ec2.internal",
    PrivateIpAddress: "172.31.37.171",
    ProductCodes: [],
    PublicDnsName: "ec2-3-82-49-212.compute-1.amazonaws.com",
    PublicIpAddress: "3.82.49.212",
    State: {
      Code: 16,
      Name: "running",
    },
    StateTransitionReason: "",
    SubnetId: "subnet-0e9e44b36072c0d79",
    VpcId: "vpc-0fba96396a307f81e",
    Architecture: "arm64",
    BlockDeviceMappings: [
      {
        DeviceName: "/dev/sda1",
        Ebs: {
          AttachTime: "2020-10-08T13:29:49+00:00",
          DeleteOnTermination: true,
          Status: "attached",
          VolumeId: "vol-01b890c3243af5c8e",
        },
      },
    ],
    ClientToken: "",
    EbsOptimized: true,
    EnaSupport: true,
    Hypervisor: "xen",
    NetworkInterfaces: [
      {
        Association: {
          IpOwnerId: "amazon",
          PublicDnsName: "ec2-3-82-49-212.compute-1.amazonaws.com",
          PublicIp: "3.82.49.212",
        },
        Attachment: {
          AttachTime: "2020-10-08T13:29:48+00:00",
          AttachmentId: "eni-attach-06e28aab623f52f6b",
          DeleteOnTermination: true,
          DeviceIndex: 0,
          Status: "attached",
        },
        Description: "",
        Groups: [
          {
            GroupName: "launch-wizard-1",
            GroupId: "sg-0774483d6588680ee",
          },
        ],
        Ipv6Addresses: [],
        MacAddress: "0e:2d:2f:17:0b:dd",
        NetworkInterfaceId: "eni-09a460796f819e285",
        OwnerId: "662108712480",
        PrivateDnsName: "ip-172-31-37-171.ec2.internal",
        PrivateIpAddress: "172.31.37.171",
        PrivateIpAddresses: [
          {
            Association: {
              IpOwnerId: "amazon",
              PublicDnsName: "ec2-3-82-49-212.compute-1.amazonaws.com",
              PublicIp: "3.82.49.212",
            },
            Primary: true,
            PrivateDnsName: "ip-172-31-37-171.ec2.internal",
            PrivateIpAddress: "172.31.37.171",
          },
        ],
        SourceDestCheck: true,
        Status: "in-use",
        SubnetId: "subnet-0e9e44b36072c0d79",
        VpcId: "vpc-0fba96396a307f81e",
        InterfaceType: "interface",
      },
    ],
    RootDeviceName: "/dev/sda1",
    RootDeviceType: "ebs",
    SecurityGroups: [
      {
        GroupName: "launch-wizard-1",
        GroupId: "sg-0774483d6588680ee",
      },
    ],
    SourceDestCheck: true,
    VirtualizationType: "hvm",
    CpuOptions: {
      CoreCount: 4,
      ThreadsPerCore: 1,
    },
    CapacityReservationSpecification: {
      CapacityReservationPreference: "open",
    },
    HibernationOptions: {
      Configured: false,
    },
    MetadataOptions: {
      State: "applied",
      HttpTokens: "optional",
      HttpPutResponseHopLimit: 1,
      HttpEndpoint: "enabled",
    },
  },
];
