import { useQuery, useResult } from "@vue/apollo-composable";
import { MetricProperty } from "@/utils/enum";
import policyMetricsQuery from "@/graphql/policyMetrics.query.gql";
import {
  getIsoUtcDateTimeFormatted,
  getFirstDateByPeriod,
} from "@/utils/datetime";

import type { Policy } from "@/types";

interface PolicyData {
  policy: Policy;
}

interface PolicyVariables {
  name: Policy["name"];
  from: string;
  to: string;
}

/**
 * Fetches metrics over a given time period for a policy
 * @param {Policy} policy
 */
export const usePolicyMetrics = (policy: Policy, period: string) => {
  const from = getIsoUtcDateTimeFormatted(getFirstDateByPeriod(period));
  const to = getIsoUtcDateTimeFormatted();

  const { result, loading, error, fetchMore } = useQuery<
    PolicyData,
    PolicyVariables
  >(
    policyMetricsQuery,
    {
      name: policy.name,
      from,
      to,
    },
    { notifyOnNetworkStatusChange: true }
  );

  const policyResult = useResult(result, null, (data) => data.policy);

  return {
    policyResult,
    from,
    to,
    loading,
    error,
    fetchMore,
  };
};

export const useFormatChartData = (
  policy: Readonly<Policy | null>,
  propertySelected: string
) => {
  if (!policy) {
    return {
      metricData: [],
      chartTitle: "",
    };
  }

  const { metricResources, metricDuration, metricApiCalls } = policy;

  if (propertySelected === MetricProperty.resourceCount) {
    return {
      metricData: metricResources?.values || [],
      chartTitle: "RESOURCE COUNT",
    };
  } else if (propertySelected === MetricProperty.executionTime) {
    return {
      metricData: metricDuration?.values || [],
      chartTitle: "EXECUTION TIME",
    };
  } else if (propertySelected === MetricProperty.apiCalls) {
    return {
      metricData: metricApiCalls?.values || [],
      chartTitle: "API CALLS",
    };
  }
};
