import {
  applyPolyfills,
  defineCustomElements,
} from "@aws-amplify/ui-components/loader";
import { DefaultApolloClient } from "@vue/apollo-composable";
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink,
} from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";

import { Auth } from "aws-amplify";
import { createApp } from "vue";
import VueApexCharts from "vue3-apexcharts";

import PrimeVue from "primevue/config";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

//TODO @marisa: install official fontawesome when release for vue3 (without plugins)
import { FontAwesomeIcon } from "@/plugins/font-awesome";

import "../src/styles/base.scss";

import App from "./App.vue";
import router from "./router";
import store from "./store";

import "bootstrap";

// Using vuex here as a means of getting a hook.
// In react, we will look at moving this to a behavior of the root component 
// For staging and higher, these values are stored on the server as part of the deployment
// For local development, these values can be stored in the public/config folder and will be loaded.
// As a fall back, they can be read off of the staging server

// getDeploymentData located in deployment vuex store
store.dispatch("getDeploymentData").then(() => {
  // Amplify config moved to the oauthclient vuex store
  store.dispatch("setAmplify").then(() => {

    // Amplify needs to be configured prior to invoking Auth, so bound by the promise scope
    const authLink = setContext(async (_, { headers }) => {
      return {
        headers: {
          ...headers,
          Authorization: `${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        },
      };
    });

    // Value set during the getDeploymentData dispatch
    const link = new HttpLink({
      uri: store.getters.deployment.gqlUri,
      fetch,
    });

    const client = new ApolloClient({
      link: ApolloLink.from([authLink, link]),
      cache: new InMemoryCache({
        addTypename: true,
        typePolicies: {
          Policies: {
            merge: true,
          },
          Policy: {
            merge: true,
          },
          PolicyExecution: {
            merge: true,
          },
        },
      }),
    });

    // Amplify polyfills will not be applied until Amplify has been configured in the vuex hook
    applyPolyfills().then(() => {
      defineCustomElements(window);
    });

    createApp(App)
      .provide(DefaultApolloClient, client)
      .use(store)
      .use(router)
      .use(VueApexCharts)
      .use(PrimeVue)
      .component("fa", FontAwesomeIcon)
      .mount("#app");
  });
});
