export const deploymentStore = {
  state: () => ({
    gqlUri: null,
  }),
  mutations: {
    FETCH_DEPLOY(state, deployment) {
      state.gqlUri = deployment.gql_uri;
    },
  },
  actions: {
    async getDeploymentData({ commit }) {
      await (await fetch("/config/deployment.json")).json().then((response) => {
        commit("FETCH_DEPLOY", response);
      });
    },
  },
  getters: {
    deployment(state) {
      return {
        gqlUri: state.gqlUri,
      };
    },
  },
};
