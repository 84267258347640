<template>
  <td data-testid="name">{{ policy.name }}</td>
  <td data-testid="resource">{{ policy.resource }}</td>
  <td v-if="loading">
    <Spinner />
  </td>
  <td v-else-if="error" class="text-danger">Error fetching metrics</td>
  <td v-else-if="policyMetricData">
    <AreaChart
      :id="`area-chart-${propertySelected}-${index}`"
      height="20"
      width="50"
      :metrics-api-data="policyMetricData.metricData"
      :from-date="from"
      :property-selected="propertySelected"
      list-view="true"
    />
  </td>
  <td>{{ policy.execMode }}</td>
  <td data-testid="tags">
    <Tag v-for="(tag, i) in policy.tags" :key="`${tag}-${i}`" :title="tag" />
  </td>
</template>

<script>
import { toRefs, watch, computed } from "vue";
import { useRouter } from "vue-router";

import AreaChart from "@/components/AreaChart";
import Tag from "@/components/Tag";
import Spinner from "@/components/Spinner";
import {
  usePolicyMetrics,
  useFormatChartData,
} from "@/composables/usePolicyMetrics";
import {
  getFirstDateByPeriod,
  getIsoUtcDateTimeFormatted,
} from "@/utils/datetime";
import { MetricProperty } from "@/utils/enum";

export default {
  name: "PolicyTableRow",
  components: { AreaChart, Spinner, Tag },
  props: {
    policy: {
      type: Object,
      required: true,
    },
    propertySelected: {
      type: String,
      required: true,
    },
    period: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();
    const linkTo = (policyName) => {
      router.push({
        name: "PolicyDetail",
        params: { policyName },
      });
    };

    const { period } = toRefs(props);

    const {
      policyResult,
      from,
      to,
      loading,
      error,
      fetchMore,
    } = usePolicyMetrics(props.policy, period.value);

    const policyMetricData = computed(() => {
      return useFormatChartData(policyResult.value, props.propertySelected);
    });

    watch(period, async (period) => {
      try {
        await fetchMore({
          variables: {
            from: getIsoUtcDateTimeFormatted(getFirstDateByPeriod(period)),
            to,
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return previousResult;
            return {
              ...previousResult,
              ...fetchMoreResult,
            };
          },
        });
      } catch (error) {
        if (error.name === "Invariant Violation") {
          // Swallowing Invariant Violation here to suppress errors when changing route
          // before `fetchMore` returns its Promise. Hopefully fixed in apollo client
          // at some point.
          // see: https://github.com/apollographql/apollo-client/issues/4114
        } else {
          throw error;
        }
      }
    });

    return {
      policyMetricData,
      from,
      linkTo,
      loading,
      error,
      Spinner,
      MetricProperty,
    };
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 1200px) {
  .fit {
    white-space: nowrap;
    width: 1%;
  }
}
</style>
