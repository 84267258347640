export const accounts = [
  {
    provider: "AWS",
    accounts: [
      {
        name: "AWS First Account",
        email: "gcp-backoffice@xyz.io",
      },
      {
        name: "Account A",
        email: "gcp-backoffice@xyz.io",
      },
      {
        name: "Account B",
        email: "gcp-backoffice@xyz.io",
      },
      {
        name: "Account C",
        email: "gcp-backoffice@xyz.io",
      },
      {
        name: "Account D",
        email: "gcp-backoffice@xyz.io",
      },
      {
        name: "Account E",
        email: "gcp-backoffice@xyz.io",
      },
      {
        name: "Account F",
        email: "gcp-backoffice@xyz.io",
      },
      {
        name: "Account G",
        email: "gcp-backoffice@xyz.io",
      },
      {
        name: "Account H",
        email: "gcp-backoffice@xyz.io",
      },
      {
        name: "Account I",
        email: "gcp-backoffice@xyz.io",
      },
      {
        name: "Account J",
        email: "gcp-backoffice@xyz.io",
      },
      {
        name: "Account K",
        email: "gcp-backoffice@xyz.io",
      },
    ],
  },
  {
    provider: "Azure",
    accounts: [
      {
        name: "Azure First Account",
        email: "gcp-backoffice@xyz.io",
      },
      {
        name: "Account A",
        email: "gcp-backoffice@xyz.io",
      },
      {
        name: "Account B",
        email: "gcp-backoffice@xyz.io",
      },
      {
        name: "Account C",
        email: "gcp-backoffice@xyz.io",
      },
      {
        name: "Account D",
        email: "gcp-backoffice@xyz.io",
      },
      {
        name: "Account E",
        email: "gcp-backoffice@xyz.io",
      },
      {
        name: "Account F",
        email: "gcp-backoffice@xyz.io",
      },
      {
        name: "Account G",
        email: "gcp-backoffice@xyz.io",
      },
    ],
  },
  {
    provider: "GCP",
    accounts: [
      {
        name: "GCP First Account",
        email: "gcp-backoffice@xyz.io",
      },
      {
        name: "Account A",
        email: "gcp-backoffice@xyz.io",
      },
      {
        name: "Account B",
        email: "gcp-backoffice@xyz.io",
      },
      {
        name: "Account C",
        email: "gcp-backoffice@xyz.io",
      },
      {
        name: "Account D",
        email: "gcp-backoffice@xyz.io",
      },
    ],
  },
  {
    provider: "Oracle",
    accounts: [
      {
        name: "Oracle First Account",
        email: "gcp-backoffice@xyz.io",
      },
      {
        name: "Account A",
        email: "gcp-backoffice@xyz.io",
      },
    ],
  },
  {
    provider: "VMWare",
    accounts: [
      {
        name: "VMWare First Account",
        email: "gcp-backoffice@xyz.io",
      },
      {
        name: "Account A",
        email: "gcp-backoffice@xyz.io",
      },
      {
        name: "Account C",
        email: "gcp-backoffice@xyz.io",
      },
    ],
  },
];
