<template>
  <div class="table-responsive" aria-label="policy table">
    <table class="table table-sm small">
      <thead class="table-light">
        <tr>
          <th class="fit">Name</th>
          <th class="fit">Resource</th>
          <th
            v-if="propertySelected === MetricProperty.policyException"
            class="fit"
          >
            Policy Exception
          </th>
          <th
            v-if="propertySelected === MetricProperty.resourceCount"
            class="fit"
          >
            Resource Count
          </th>
          <th
            v-if="propertySelected === MetricProperty.executionTime"
            class="fit"
          >
            Execution Time
          </th>
          <th v-if="propertySelected === MetricProperty.apiCalls" class="fit">
            Api Calls
          </th>
          <th class="fit">Run Mode</th>
          <th class="fit">Tags</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(policy, index) in policies"
          :key="index"
          class="button st-table-hover"
          role="button"
          @click="linkTo(policy.name)"
          @keypress="linkTo(policy.name)"
        >
          <PolicyTableRow
            :policy="policy"
            :property-selected="propertySelected"
            :period="period"
            :index="index"
          />
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { useRouter } from "vue-router";

import PolicyTableRow from "@/components/PolicyTableRow";
import { MetricProperty } from "@/utils/enum";

export default {
  name: "PolicyTable",
  components: { PolicyTableRow },
  props: {
    policies: {
      type: Array,
      required: true,
    },
    propertySelected: {
      type: String,
      required: true,
    },
    period: {
      type: String,
      required: true,
    },
  },
  setup() {
    const router = useRouter();
    const linkTo = (policyName) => {
      router.push({
        name: "PolicyDetail",
        params: { policyName },
      });
    };

    return {
      linkTo,
      MetricProperty,
    };
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 1200px) {
  .fit {
    white-space: nowrap;
    width: 1%;
  }
}
</style>
