export const TimePeriod = Object.freeze({
  daily: "one_day",
  weekly: "one_week",
  monthly: "one_month",
});

export const MetricProperty = Object.freeze({
  policyException: "policyException",
  resourceCount: "resourceCount",
  executionTime: "executionTime",
  apiCalls: "apiCalls",
});

export const PoliciesFilter = Object.freeze({
  categories: ["Security", "Operations", "CostOptimization"],
  compliances: ["APRA", "MAS", "HIPAA", "PCI", "CIS", "GDPR"],
  providers: ["AWS", "GCP", "Azure"],
  resources: ["EC2", "S3", "RDS"],
});
