export const policiesResource = [
  {
    name: "ec2-auto-tag-user",
    lastNonCompliant: "2020-12-10T16:50:10.643548",
    firstDiscovered: "2020-12-10T16:50:03.789932",
  },
  {
    name: "ec2-copy-instance-tags-to-volumes",
    lastNonCompliant: "2020-12-10T16:49:40.028140",
    firstDiscovered: "2020-12-10T16:49:35.851111",
  },
  {
    name: "ec2-daily-backup",
    lastNonCompliant: "2020-12-10T16:48:53.866479",
    firstDiscovered: "2020-12-10T16:48:49.090298",
  },
  {
    name: "ec2-delete-marked",
    lastNonCompliant: "2020-12-10T16:47:20.100441",
    firstDiscovered: "2020-12-10T16:47:13.362149",
  },
  {
    name: "ec2-fix-contact-tags",
    lastNonCompliant: "2020-12-10T16:47:06.378725",
    firstDiscovered: "2020-12-10T16:47:03.393342",
  },
  {
    name: "ec2-fix-env-acc-tags",
    lastNonCompliant: "2020-12-10T16:45:56.053503",
    firstDiscovered: "2020-12-10T16:45:51.289021",
  },
  {
    name: "ec2-fix-env-dev-tags",
    lastNonCompliant: "2020-12-10T16:45:50.265219",
    firstDiscovered: "2020-12-10T16:45:45.080333",
  },
  {
    name: "ec2-fix-env-non-prod-tags",
    lastNonCompliant: "2020-12-10T16:45:28.514205",
    firstDiscovered: "2020-12-10T16:45:23.526234",
  },
  {
    name: "ec2-fix-env-null-tags",
    lastNonCompliant: "2020-12-10T16:45:24.273385",
    firstDiscovered: "2020-12-10T16:45:19.382943",
  },
  {
    name: "ec2-fix-env-odd-valued-tags",
    lastNonCompliant: "2020-12-10T16:45:25.463857",
    firstDiscovered: "2020-12-10T16:45:19.130192",
  },
  {
    name: "ec2-fix-env-prd-tags",
    lastNonCompliant: "2020-12-10T16:45:23.491750",
    firstDiscovered: "2020-12-10T16:45:17.848327",
  },
  {
    name: "ec2-fix-env-qa-tags",
    lastNonCompliant: "2020-12-10T16:44:27.899308",
    firstDiscovered: "2020-12-10T16:44:22.279860",
  },
  {
    name: "ec2-fix-env-tst-tags",
    lastNonCompliant: "2020-12-10T16:42:51.658995",
    firstDiscovered: "2020-12-10T16:42:47.676577",
  },
  {
    name: "ec2-fix-missing-backup-tag",
    lastNonCompliant: "2020-12-10T16:42:27.413903",
    firstDiscovered: "2020-12-10T16:42:23.215743",
  },
  {
    name: "ec2-fix-resource-contact-tags",
    lastNonCompliant: "2020-12-10T16:41:19.514081",
    firstDiscovered: "2020-12-10T16:41:16.736967",
  },
  {
    name: "ec2-fix-ta-tags",
    lastNonCompliant: "2020-12-10T16:39:49.012434",
    firstDiscovered: "2020-12-10T16:39:43.866987",
  },
  {
    name: "ec2-instance-too-large-notify",
    lastNonCompliant: "2020-12-10T16:39:47.473110",
    firstDiscovered: "2020-12-10T16:39:42.096644",
  },
  {
    name: "ec2-mark-stopped-instance",
    lastNonCompliant: "2020-12-10T16:39:20.048331",
    firstDiscovered: "2020-12-10T16:39:14.320917",
  },
  {
    name: "ec2-mark-stopped-instance-realtime",
    lastNonCompliant: "2020-12-10T16:38:57.792706",
    firstDiscovered: "2020-12-10T16:38:54.390552",
  },
  {
    name: "ec2-normalize-backup-tag",
    lastNonCompliant: "2020-12-10T16:37:49.335670",
    firstDiscovered: "2020-12-10T16:37:45.013823",
  },
  {
    name: "ec2-normalize-environment-tag",
    lastNonCompliant: "2020-12-10T16:37:12.866904",
    firstDiscovered: "2020-12-10T16:37:09.408303",
  },
  {
    name: "ec2-normalize-resource-contact-tags-to-uppercase",
    lastNonCompliant: "2020-12-10T16:36:40.158892",
    firstDiscovered: "2020-12-10T16:36:36.629551",
  },
  {
    name: "ec2-notify-before-delete-marked-1-days",
    lastNonCompliant: "2020-12-10T16:36:14.257471",
    firstDiscovered: "2020-12-10T16:36:09.537211",
  },
  {
    name: "ec2-notify-before-delete-marked-14-days",
    lastNonCompliant: "2020-12-10T16:35:36.008638",
    firstDiscovered: "2020-12-10T16:35:30.481335",
  },
  {
    name: "ec2-notify-before-delete-marked-7-days",
    lastNonCompliant: "2020-12-10T16:33:57.069779",
    firstDiscovered: "2020-12-10T16:33:53.048824",
  },
  {
    name: "ec2-offhours-start",
    lastNonCompliant: "2020-12-10T16:32:16.990104",
    firstDiscovered: "2020-12-10T16:32:12.323819",
  },
  {
    name: "ec2-offhours-stop",
    lastNonCompliant: "2020-12-10T16:31:39.498923",
    firstDiscovered: "2020-12-10T16:31:33.241967",
  },
  {
    name: "ec2-require-non-public-and-encrypted-volumes",
    lastNonCompliant: "2020-12-10T16:31:03.140007",
    firstDiscovered: "2020-12-10T16:30:56.771047",
  },
  {
    name: "ec2-tag-compliance-mark",
    lastNonCompliant: "2020-12-10T16:29:32.697444",
    firstDiscovered: "2020-12-10T16:29:28.797889",
  },
  {
    name: "ec2-tag-compliance-nag-stop",
    lastNonCompliant: "2020-12-10T16:29:22.533061",
    firstDiscovered: "2020-12-10T16:29:17.235933",
  },
  {
    name: "ec2-tag-compliance-stop",
    lastNonCompliant: "2020-12-10T11:01:03.813025",
    firstDiscovered: "2020-12-10T11:00:59.366552",
  },
  {
    name: "ec2-tag-compliance-terminate",
    lastNonCompliant: "2020-12-10T10:55:35.199938",
    firstDiscovered: "2020-12-10T10:55:28.420502",
  },
  {
    name: "ec2-tag-compliance-unmark",
    lastNonCompliant: "2020-12-10T10:54:01.168128",
    firstDiscovered: "2020-12-10T10:53:55.714389",
  },
  {
    name: "ec2-tag-stateful-instance-at-launch",
    lastNonCompliant: "2020-12-10T10:53:43.819587",
    firstDiscovered: "2020-12-10T10:53:40.070782",
  },
  {
    name: "ec2-tag-stateless-instance-at-launch",
    lastNonCompliant: "2020-12-10T16:37:12.866904",
    firstDiscovered: "2020-12-10T16:37:09.408303",
  },
];
