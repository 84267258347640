<template>
  <div class="search-wrapper">
    <form class="search-bar" @submit.prevent="handleSubmit">
      <a href="#" role="button">
        <fa icon="search" class="s-icon"></fa>
      </a>
      <input
        v-model="state.searchKeyword"
        class="form-control"
        type="text"
        name="search"
        aria-label="Search"
        placeholder="Search"
        @input="handleSubmit"
      />
    </form>
  </div>
</template>

<script>
import { reactive } from "vue";
export default {
  name: "SearchBar",
  emits: ["search"],
  setup(props, context) {
    const state = reactive({
      searchKeyword: "",
    });
    const handleSubmit = (event) => {
      event.preventDefault();
      context.emit("search", state.searchKeyword);
    };
    return {
      state,
      handleSubmit,
    };
  },
};
</script>

<style lang="scss" scoped>
.search-wrapper {
  margin-right: 20px;
  position: relative;

  .search-bar {
    height: 40px;
    width: 100%;
  }

  .s-icon {
    left: 10px;
    position: absolute;
    top: 10px;
    width: 18px;
  }
}

input {
  padding-left: 35px;
}
</style>
