<template>
  <span class="badge" aria-label="tag">{{ title }}</span>
</template>

<script>
export default {
  name: "Tag",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.badge {
  background-color: $st-light-gray;
  border-radius: 16px;
  color: $black;
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 5px;
  margin-right: 5px;
  min-height: 27px;
  min-width: 60px;
  padding-left: 15px;
  padding-right: 15px;
  vertical-align: middle;
  width: auto;
}
</style>
