<template>
  <div v-for="(item, i) in data" :key="`${item}-${i}`" class="form-check">
    <label class="form-check-label" :for="item">
      {{ item }}
      <input
        :id="item"
        v-model="state.storeDataArray"
        class="form-check-input"
        type="checkbox"
        :value="item"
      />
    </label>
  </div>
</template>

<script>
import { reactive, watchEffect } from "vue";

export default {
  name: "Checkbox",
  props: {
    data: {
      type: Object,
      required: true,
    },
    modelValue: {
      type: Array,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const state = reactive({
      storeDataArray: props.modelValue,
    });

    watchEffect(() => {
      emit("update:modelValue", state.storeDataArray);
    });

    watchEffect(() => {
      state.storeDataArray = props.modelValue;
    });

    return {
      state,
    };
  },
};
</script>

<style lang="scss" scoped>
.form-check {
  padding: 0.5rem 1rem;
  padding-left: 35px;
}

.form-check-input {
  height: 16px;
  width: 16px;
}

.form-check-input:checked {
  background-color: $black;
  border-color: $black;
}
</style>
