<template>
  <div class="spinner-border text-secondary" role="status">
    <p class="sr-only">Loading &hellip;</p>
  </div>
</template>

<script>
export default {
  name: "Spinner",
};
</script>
