<template>
  <nav class="navbar navbar-dark sticky-top navbar-expand-sm small">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/">
        <img
          src="@/assets/images/logo_white.png"
          alt="stacklet_logo"
          height="24"
        />
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div id="navbarSupportedContent" class="collapse navbar-collapse">
        <ul class="nav-item navbar-nav">
          <li></li>
          <li class="nav-item">
            <router-link
              id="policies"
              class="nav-link nav-middle-item"
              active-class="active"
              to="/policies"
            >
              Policies
            </router-link>
            <router-link
              id="admin"
              class="nav-link nav-middle-item"
              active-class="active"
              to="/admin"
            >
              Admin
            </router-link>
          </li>
          <li class="nav-item">
            <div class="nav-item dropdown profile-dropdown-wrapper">
              <a
                id="navbarDropdown"
                class="nav-link dropdown-toggle name-profile"
                href="#"
                role="button"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <fa icon="user-circle" class="nav-item nav-icon"></fa>
                {{ user?.username }}
              </a>
              <ul
                class="dropdown-menu dd-profile"
                aria-labelledby="navbarDropdown"
              >
                <li>
                  <a
                    class="dropdown-item"
                    @click="openGraphQLPlayround"
                    @keypress="openGraphQLPlayround"
                    >Stacklet API</a
                  >
                </li>
                <li><amplify-sign-out></amplify-sign-out></li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { Auth } from "aws-amplify";
export default {
  name: "Nav",
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  methods: {
    async openGraphQLPlayround() {
      const baseUrl = this.$store.getters.deployment.gqlUri;

      const tabsData = [
        {
          endpoint: baseUrl,
          headers: {
            authorization: `${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
          },
        },
      ];

      const tabsQueryParam = new URLSearchParams({
        tabs: JSON.stringify(tabsData),
      }).toString();
      window.open(`${baseUrl}?${tabsQueryParam}`, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar,
.nav-link {
  background-color: #212b36;
  height: 64px;

  .nav-link {
    color: $white;
  }

  .nav-icon {
    margin-right: 5px;
    width: 16px;
  }

  .dd-profile {
    left: auto;
    right: 0;
  }

  .navbar-nav {
    width: 100%;
  }

  .nav-item a {
    align-items: center;
    display: flex;
    height: 64px;
    justify-content: center;
  }
}

.nav-item,
.navbar-nav {
  justify-content: space-between;
}

.navbar-brand {
  display: flex;
  margin-left: 5px;
}

.active {
  background-color: $black;
  border-bottom: $st-orange solid 2px;
  font-weight: bold;
}

.navbar-toggler {
  border: 1px solid $st-orange;
  filter: invert(35%) sepia(60%) saturate(822%) hue-rotate(340deg)
    brightness(102%) contrast(93%);
}

li,
.nav-icon {
  display: flex;
}

@media screen and (max-width: 576px) {
  .nav-item {
    flex-direction: column;
  }
}

@media screen and (min-width: 576px) {
  .nav-middle-item {
    width: 142px;
  }
}
</style>
