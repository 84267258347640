import { createStore } from "vuex";
import { instances } from "../assets/data_temp/ec2_instances";
import { repositories } from "../assets/data_temp/repositories";
import { accounts } from "../assets/data_temp/accounts";
import { policiesResource } from "../assets/data_temp/policiesResource";
import { identityStore } from "./oauthclient";
import { deploymentStore } from "./deployment";

const state = {
  resources: instances,
  repositories,
  accounts,
  policiesResource,
};

export default createStore({
  state,
  modules: {
    identityStore,
    deploymentStore,
  },
});
