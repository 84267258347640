<template>
  <ul class="pagination">
    <li class="page-item">
      <span aria-label="PagesSize"> Items per page: </span>
      <input
        class="page-size-input text-center"
        :value="pageSize"
        name="pageSize"
        @blur="handlePageSizeChange(parseInt($event.target.value))"
        @keyup.enter="handlePageSizeChange(parseInt($event.target.value))"
      />
    </li>
    <li>
      <img
        v-if="hasPreviousPage"
        src="../assets/icon/arrow_left.svg"
        class="link-icon button left-arrow"
        alt="previous page"
        role="button"
        tabindex="0"
        @click="loadMore('previous', pageSize)"
        @keypress="loadMore('previous', pageSize)"
      />
      <img
        v-if="hasNextPage"
        src="../assets/icon/arrow_right.svg"
        class="link-icon button"
        alt="next page"
        role="button"
        tabindex="0"
        @click="loadMore('next', pageSize)"
        @keypress="loadMore('next', pageSize)"
      />
    </li>
  </ul>
</template>

<script>
export default {
  name: "PaginationApi",
  props: {
    pageSize: {
      type: Number,
      required: true,
    },
    loadMore: {
      type: Function,
      required: true,
    },
    hasNextPage: {
      type: Boolean,
      required: true,
    },
    hasPreviousPage: {
      type: Boolean,
      required: true,
    },
    handlePageSizeChange: {
      type: Function,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.pagination {
  display: flex;
  justify-content: space-between;
  margin: 32px 0 48px;
}

.page-size-input {
  width: 50px;
}

.page-item > select {
  height: 30px;
  width: 50px;
}

.link-icon {
  background-color: $white;
  border: 1px solid #dfe3e8;
  border-radius: 2px;
  height: 30px;
  padding: 4px;
  width: 30px;

  &:hover {
    background-color: #efefef;
  }
}

.left-arrow {
  margin-right: 10px;
}
</style>
