<template>
  <div class="container-fluid">
    <div class="row">
      <div
        v-for="(policy, index) in policies"
        :key="index"
        class="col-xs-12 col-md-6 col-xl-4 col-xxl-3"
      >
        <router-link
          :key="policy.name"
          class="router-link"
          :to="{
            name: 'PolicyDetail',
            params: { policyName: policy.name },
          }"
        >
          <PolicyCard
            :policy="policy"
            :property-selected="propertySelected"
            :period="period"
            :index="index"
            @mouseover="prefetchPolicyDetails(policy)"
            @focus="prefetchPolicyDetails(policy)"
          />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import PolicyCard from "@/components/PolicyCard";
import policyQuery from "@/graphql/policy.query.gql";
import { useLazyQuery } from "@vue/apollo-composable";

export default {
  name: "PolicyCardGrid",
  components: {
    PolicyCard,
  },
  props: {
    policies: {
      type: Array,
      required: true,
    },
    propertySelected: {
      type: String,
      required: true,
    },
    period: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { load } = useLazyQuery(policyQuery);

    const prefetchPolicyDetails = (policy) => {
      load(policyQuery, { name: policy.name });
    };

    return {
      prefetchPolicyDetails,
    };
  },
};
</script>

<style lang="scss" scoped>
.router-link {
  color: $black;
  text-decoration: none;
}
</style>
