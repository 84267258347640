<template>
  <div :class="{ 'area-chart-wrapper': !listView }">
    <apexchart
      :height="height"
      :width="width"
      type="area"
      :options="chartOptions"
      :series="[{ data: seriesValue }]"
      :class="{ 'area-chart': !listView }"
    ></apexchart>
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  name: "AreaChart",
  props: {
    metricsApiData: {
      type: Array,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    fromDate: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      default: "155",
    },
    width: {
      type: String,
      default: "385",
    },
    listView: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const tooltipData = props.listView
      ? {
          enabled: false,
        }
      : {
          onDatasetHover: {
            highlightDataSeries: false,
          },
          x: {
            show: false,
          },
          y: {
            title: {
              formatter() {
                return "value:";
              },
            },
          },
        };
    const sparklineEnable = props.listView
      ? {
          enabled: true,
        }
      : "";

    return {
      seriesValue: computed(() => {
        return props.metricsApiData.map((data) => {
          return [new Date(data.date).getTime(), data.value];
        });
      }),
      chartOptions: {
        colors: ["#C05621"],
        chart: {
          id: props.id,
          toolbar: {
            show: false,
          },
          sparkline: sparklineEnable,
        },
        grid: {
          show: false,
          padding: {
            left: 0,
            right: 0,
          },
        },
        tooltip: tooltipData,
        yaxis: {
          show: false,
          type: "numeric",
          min: 0,
          labels: {
            formatter: (val) => {
              // Override the default behaviour for graphs with values
              // lower then default tickAmount (6), which renders
              // integers as floats
              // https://github.com/apexcharts/apexcharts.js/issues/779
              if (Number.isInteger(val)) {
                return val.toFixed(0);
              }
              return val.toFixed(2);
            },
          },
        },
        xaxis: {
          type: "datetime",
          labels: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          min: props.fromDate,
          max: new Date().getTime(),
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },
        dataLabels: {
          enabled: false,
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.area-chart-wrapper {
  height: 150px;

  .area-chart {
    padding-top: 16px;
  }
}
</style>
