export const repositories = [
  {
    name: "special-aws-policy",
    provider: "Github",
    status: "Active",
    dateTitle: "Today - Oct 31, 2020",
    commits: [
      {
        title: "Adding in new policies for Lambda",
        description:
          "Adding Lambda policies and adding Operations and renaming Cost Savings dir",
        author: "jtroberts83",
        sha: "7febb7b",
        commitDate: "27 days ago",
      },
    ],
  },
  {
    name: "special-aws-policy",
    provider: "Github",
    status: "Inactive",
    dateTitle: "Yesterday - Oct 30, 2020",
    commits: [
      {
        title: "Adding in new policies for Lambda",
        description:
          "Adding Lambda policies and adding Operations and renaming Cost Savings dir",
        author: "jtroberts83",
        sha: "7febb7b",
        commitDate: "27 days ago",
      },
      {
        title: "Adding in new policies for Lambda",
        description:
          "Adding Lambda policies and adding Operations and renaming Cost Savings dir",
        author: "jtroberts83",
        sha: "7febb7b",
        commitDate: "27 days ago",
      },
      {
        title: "Adding in new policies for Lambda",
        description:
          "Adding Lambda policies and adding Operations and renaming Cost Savings dir",
        author: "jtroberts83",
        sha: "7febb7b",
        commitDate: "27 days ago",
      },
    ],
  },
  {
    name: "cloud-policies",
    provider: "Github",
    status: "Active",
    dateTitle: "Oct 29, 2020",
    commits: [
      {
        title: "Adding in new policies for Lambda",
        description:
          "Adding Lambda policies and adding Operations and renaming Cost Savings dir",
        author: "jtroberts83",
        sha: "7febb7b",
        commitDate: "27 days ago",
      },
    ],
  },
  {
    name: "cloud-policies",
    provider: "Github",
    status: "Active",
    dateTitle: "Oct 28, 2020",
    commits: [
      {
        title: "Adding in new policies for Lambda",
        description:
          "Adding Lambda policies and adding Operations and renaming Cost Savings dir",
        author: "jtroberts83",
        sha: "7febb7b",
        commitDate: "27 days ago",
      },
      {
        title: "Adding in new policies for Lambda",
        description:
          "Adding Lambda policies and adding Operations and renaming Cost Savings dir",
        author: "jtroberts83",
        sha: "7febb7b",
        commitDate: "27 days ago",
      },
      {
        title: "Adding in new policies for Lambda",
        description:
          "Adding Lambda policies and adding Operations and renaming Cost Savings dir",
        author: "jtroberts83",
        sha: "7febb7b",
        commitDate: "27 days ago",
      },
    ],
  },
];
