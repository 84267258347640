<template>
  <h2>HOME PAGE</h2>
  <div>
    <h3>GraphQL DATA EXAMPLE:</h3>
  </div>
  <Policies />
</template>

<script>
import Policies from "../views/Policies.vue";

export default {
  name: "Home",
  components: { Policies },
};
</script>
