<template>
  <div class="card card-wrapper" aria-label="policy card">
    <ChartsContainer
      :id="`area-chart-${propertySelected}-${index}`"
      :metric-data="policyMetricData?.metricData"
      :from-date="from"
      :chart-title="policyMetricData?.chartTitle"
      :loading="loading"
      :error="error"
    />
    <div class="card-body">
      <h5 class="card-title" data-testid="name">{{ policyName }}</h5>
      <h6 class="card-subtitle text-muted" data-testid="resource">
        {{ policy.resource }}
      </h6>
      <div v-if="policy.tags" class="tags-wrapper" data-testid="tags">
        <Tag
          v-for="(tag, i) in policy.tags"
          :key="`${tag}-${i}`"
          :title="tag"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, toRefs, watch, PropType } from "vue";

import ChartsContainer from "@/components/ChartsContainer.vue";
import Tag from "@/components/Tag.vue";
import {
  usePolicyMetrics,
  useFormatChartData,
} from "@/composables/usePolicyMetrics";
import {
  getFirstDateByPeriod,
  getIsoUtcDateTimeFormatted,
} from "@/utils/datetime";
import { formatPolicyName } from "@/utils/formatting";
import { MetricProperty } from "@/utils/enum";

import type { $TSFixMe, Policy } from "@/types";

export default defineComponent({
  name: "PolicyCard",
  components: {
    Tag,
    ChartsContainer,
  },
  props: {
    policy: {
      type: Object as PropType<Policy>,
      required: true,
    },
    propertySelected: {
      type: String,
      required: true,
    },
    period: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const { period } = toRefs(props);

    const policyName = computed(() => formatPolicyName(props.policy));

    const {
      policyResult,
      from,
      to,
      loading,
      error,
      fetchMore,
    } = usePolicyMetrics(props.policy, period.value);

    const policyMetricData = computed(() =>
      useFormatChartData(policyResult.value, props.propertySelected)
    );

    watch(period, async (period) => {
      try {
        await fetchMore({
          variables: {
            from: getIsoUtcDateTimeFormatted(getFirstDateByPeriod(period)),
            to,
          },
          updateQuery: (
            previousResult: $TSFixMe,
            { fetchMoreResult }: $TSFixMe
          ) => {
            if (!fetchMoreResult) return previousResult;
            return {
              ...previousResult,
              ...fetchMoreResult,
            };
          },
        });
      } catch (error) {
        if (error.name === "Invariant Violation") {
          // Swallowing Invariant Violation here to suppress errors when changing route
          // before `fetchMore` returns its Promise. Hopefully fixed in apollo client
          // at some point.
          // see: https://github.com/apollographql/apollo-client/issues/4114
        } else {
          throw error;
        }
      }
    });

    return {
      policyMetricData,
      policyName,
      from,
      loading,
      error,
      MetricProperty,
    };
  },
});
</script>

<style lang="scss" scoped>
.card-wrapper {
  cursor: pointer;
  font-weight: normal;
  margin: 10px 5px;
  min-height: 385px;
  padding: 12px 24px;
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: $box-shadow-lg;
  }
}

.card-title,
.tags-wrapper {
  overflow: hidden;
  padding-top: 32px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.card-body {
  padding: 32px 0 0;
}
</style>
