<template>
  <div class="app">
    <amplify-authenticator
      v-if="authState !== 'signedin'"
    ></amplify-authenticator>
    <div v-if="authState === 'signedin' && user">
      <Nav :user="user" />
      <div class="app-body">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import Nav from "./components/Nav";
import { onAuthUIStateChange } from "@aws-amplify/ui-components";

export default {
  name: "App",
  components: { Nav },
  data() {
    return {
      user: this.user,
      authState: this.authState,
      unsubscribeAuth: this.unsubscribeAuth,
    };
  },
  watch: {
    $route(to) {
      document.title = to.meta.title || "Stacklet";
    },
  },
  created() {
    this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData;
    });
  },
  beforeUnmount() {
    this.unsubscribeAuth();
  },
};
</script>
