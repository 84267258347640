import dayjs from "dayjs";

import { TimePeriod } from "@/utils/enum";

// Example output: March 2, 2020
// this gives back browser timezone
export const getFormatDate = (dateString) => {
  const options = { month: "long", day: "numeric", year: "numeric" };
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", options);
};

//Example output: Dec 10, 2020 07:15:17
// this gives back browser timezone
export const getFormatDateTime = (dateString) => {
  const date = new Date(dateString);

  return dayjs(date).format("MMM D, YYYY HH:mm:ssZ[Z]");
};

export const getLocalDateTime = (dateString) => {
  const options = {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    // hour12: false, //if want to change to 24 hours instead of using AM/PM
    timeZoneName: "short",
  };
  const date = new Date(dateString);
  return date.toLocaleString("en-US", options);
};

//Example output: Jan 15, 2021, 12:35 PM UTC
export const getUTCDateTime = (dateString) => {
  if (!dateString) return null;

  const options = {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    // hour12: false, //if want to change to 24 hours instead of using AM/PM
    timeZone: "UTC",
    timeZoneName: "short",
  };
  const date = new Date(dateString);
  return date.toLocaleString("en-US", options);
};

//Input must be date in UTC
//Example Output: 2021-02-03T01:44:15+00:00
export const getIsoUtcDateTimeFormatted = (dateInUTC) => {
  let date;
  if (!dateInUTC) {
    date = new Date();
  } else {
    date = new Date(dateInUTC);
  }

  return `${date.toISOString().split(".")[0]}+00:00`;
};

//get time in millisecond
export const getTimeMillis = (dateString) => {
  const date = new Date(dateString);
  return date.getTime();
};

export const millisToTime = (duration) => {
  const milliseconds = parseInt((duration % 1000) / 100);
  let seconds = Math.floor((duration / 1000) % 60);
  let minutes = Math.floor((duration / (1000 * 60)) % 60);
  let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;

  return `${hours}:${minutes}:${seconds}.${milliseconds}`;
};

//get last month in milliseconds (Unix epoch time) relative to now
export const getLastMonth = () => {
  return dayjs().subtract(1, "month").valueOf();
};

//get last week in milliseconds (Unix epoch time) relative to now
export const getLastWeek = () => {
  return dayjs().subtract(1, "week").valueOf();
};

//get last day in milliseconds (Unix epoch time) relative to now
export const getLastDay = () => {
  return dayjs().subtract(1, "day").valueOf();
};

/**
 * Get last month, week or day in given period
 * @enum {TimePeriod} period
 * @returns unix epoch timestamp
 */
export const getFirstDateByPeriod = (period) => {
  switch (period) {
    case TimePeriod.monthly:
      return getLastMonth();
    case TimePeriod.weekly:
      return getLastWeek();
    default:
      return getLastDay();
  }
};
