<template>
  <div class="filter-list-wrapper" @submit.prevent="handleSubmitFilter">
    <form>
      <Accordion title="Category">
        <template #default>
          <Checkbox
            v-model="state.categories"
            :data="PoliciesFilter.categories"
          />
        </template>
      </Accordion>

      <Accordion title="Compliance">
        <template #default>
          <Checkbox
            v-model="state.compliances"
            :data="PoliciesFilter.compliances"
          />
        </template>
      </Accordion>

      <Accordion title="Provider">
        <template #default>
          <DropdownMenu
            v-model="state.selectProvider"
            :data="PoliciesFilter.providers"
            placeholder-msg="Select a Provider"
          />
        </template>
      </Accordion>

      <Accordion title="Resource Type">
        <template #default>
          <Checkbox
            v-model="state.resources"
            :data="PoliciesFilter.resources"
          />
        </template>
      </Accordion>

      <div class="filter-btn-wrapper">
        <a
          class="button clear-btn"
          role="button"
          tabindex="0"
          @click="handleClearAll"
          @keypress="handleClearAll"
          >Clear All</a
        >
        <button
          tabindex="0"
          class="filter-submit-btn"
          @click="handleSubmitFilter"
          @keypress="handleSubmitFilter"
        >
          Submit Filter
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { reactive } from "vue";
import { PoliciesFilter } from "@/utils/enum";
import Accordion from "@/components/Accordion";
import DropdownMenu from "@/components/DropdownMenu";
import Checkbox from "@/components/Checkbox.vue";

export default {
  name: "PoliciesFilter",
  components: { Accordion, DropdownMenu, Checkbox },
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },

  setup(props, context) {
    const state = reactive({
      categories: props.filters.categories,
      compliances: props.filters.compliances,
      itemsSelectedCount: 0,
      resources: props.filters.resources,
      selectProvider: props.filters.selectProvider,
    });

    const handleClearAll = () => {
      state.categories = [];
      state.compliances = [];
      state.resources = [];
      state.selectProvider = null;
      state.itemsSelectedCount = 0;
    };

    const handleSubmitFilter = (event) => {
      event.preventDefault();
      const DEFAULT_PROVIDER = "aws";
      const providerQuery = state.selectProvider
        ? state.selectProvider
        : DEFAULT_PROVIDER;
      const categoriesQuery = state.categories.map(
        (category) => `tag:Category::${category}`
      );
      const compliancesQuery = state.compliances.map(
        (compliance) => `Compliance::${compliance}`
      );
      const resourcesQuery = state.resources.map(
        (resource) =>
          `resource:${providerQuery.toLowerCase()}.${resource.toLowerCase()}`
      );

      const filterQuery = [
        providerQuery,
        ...categoriesQuery,
        ...compliancesQuery,
        ...resourcesQuery,
      ].join(" ");

      state.itemsSelectedCount =
        state.categories.length +
        state.compliances.length +
        state.resources.length;

      state.itemsSelectedCount = state.selectProvider
        ? state.itemsSelectedCount + 1
        : state.itemsSelectedCount;

      const filtersSelected = {
        compliances: state.compliances,
        categories: state.categories,
        resources: state.resources,
        selectResource: state.selectResource,
        selectProvider: state.selectProvider,
      };

      const isFilterQueryEmpty = !Boolean(filterQuery.replace(/\s/g, ""));

      context.emit(
        "filter",
        filterQuery,
        state.itemsSelectedCount,
        filtersSelected,
        isFilterQueryEmpty
      );
    };
    return {
      state,
      handleClearAll,
      handleSubmitFilter,
      PoliciesFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.clear-btn {
  text-decoration: underline;
}

.filter-btn-wrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 20px 30px;
}

.filter-submit-btn {
  background-color: $st-orange;
  border: 0;
  border-radius: 2px;
  color: $white;
  display: flex;
  font-size: 16px;
  height: 48px;
  padding: 10px 15px;
}
</style>
