<template>
  <Dropdown
    v-model="state.storeDataArray"
    :options="data"
    :show-clear="true"
    :placeholder="placeholderMsg"
  />
</template>

<script>
import { reactive, watchEffect } from "vue";
import Dropdown from "primevue/dropdown";

export default {
  name: "DropdownMenu",
  components: { Dropdown },
  props: {
    data: {
      type: Object,
      required: true,
    },
    modelValue: {
      validator: (prop) => typeof prop === "string" || prop === null,
      required: true,
    },
    placeholderMsg: {
      type: String,
      default: "Select an Option",
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const state = reactive({
      storeDataArray: props.modelValue,
    });

    watchEffect(() => {
      emit("update:modelValue", state.storeDataArray);
    });

    watchEffect(() => {
      state.storeDataArray = props.modelValue;
    });

    return {
      state,
    };
  },
};
</script>
