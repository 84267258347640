import { computed } from "vue";

/**
 * Vue composable for building a standard loadMore function
 * for refetching paginated Graphql data
 *
 * @param {Function} fetchMore - apollo fetchMore function returned by useQuery
 * @param {PageInfo} pageInfo - pageInfo object from graphql response
 * @param {Function} updateQuery (optional) - custom update resolution function
 * @returns {Boolean} hasPreviousPage
 * @returns {Boolean} hasNextPage
 * @returns {Function} loadMore
 */
const useLoadMore = (fetchMore, pageInfo, updateQuery) => {
  const hasPreviousPage = computed(() => pageInfo.value.hasPreviousPage);
  const hasNextPage = computed(() => pageInfo.value.hasNextPage);

  const loadMore = async (direction, pageSize) => {
    const start = computed(() => pageInfo.value.startCursor);
    const end = computed(() => pageInfo.value.endCursor);
    try {
      await fetchMore({
        variables: {
          first: pageSize,
          last: pageSize,
          before: (direction === "previous" && start.value) || "",
          after: (direction === "next" && end.value) || "",
        },
        updateQuery: updateQuery
          ? updateQuery
          : (previousResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) return previousResult;
              return {
                ...previousResult,
                ...fetchMoreResult,
              };
            },
      });
    } catch (error) {
      if (error.name === "Invariant Violation") {
        // Swallowing Invariant Violation here to suppress errors when changing route
        // before `fetchMore` returns its Promise. Hopefully fixed in apollo client
        // at some point.
        // see: https://github.com/apollographql/apollo-client/issues/4114
      } else {
        throw error;
      }
    }
  };

  return {
    hasPreviousPage,
    hasNextPage,
    loadMore,
  };
};

export default useLoadMore;
