import Amplify from "aws-amplify";

export const identityStore = {
  state: () => ({
    region: null,
    userPoolId: null,
    userPoolWebClientId: null,
    authenticationFlowType: null,
  }),
  mutations: {
    FETCH_CONFIG(state, config) {
      state.region = config.region;
      state.userPoolId = config.cognito_user_pool_id;
      state.userPoolWebClientId = config.cognito_user_pool_client_id;
      state.authenticationFlowType = "USER_PASSWORD_AUTH";
    },
  },
  actions: {
    async getAuthData({ commit }) {
      await (await fetch("/config/cognito.json")).json().then((response) => {
        commit("FETCH_CONFIG", response);
      });
    },
    setAmplify({ getters, dispatch }) {
      return dispatch("getAuthData").then(() => {
        const config = getters.config;
        Amplify.configure({
          Auth: {
            region: config.region,
            userPoolId: config.userPoolId,
            userPoolWebClientId: config.userPoolWebClientId,
            mandatorySignIn: true,
            authenticationFlowType: config.authenticationFlowType,
          },
        });
      });
    },
  },
  getters: {
    config(state) {
      return {
        region: state.region,
        userPoolId: state.userPoolId,
        userPoolWebClientId: state.userPoolWebClientId,
        authenticationFlowType: "USER_PASSWORD_AUTH",
      };
    },
  },
};
