<template>
  <div class="collapse-full-tab-wrapper accordion">
    <div>
      <button
        class="btn collapse-button"
        type="button"
        data-toggle="collapse"
        :data-target="`#${id}`"
        aria-expanded="true"
        :aria-controls="title"
      >
        <div class="group-title">{{ title }}</div>
        <fa icon="caret-down" class="s-icon"></fa>
      </button>
    </div>
    <div :id="id" class="collapse show card card-body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Accordion",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const id = props.title.replace(/\s/g, "");
    return {
      id,
    };
  },
};
</script>

<style lang="scss" scoped>
.group-title {
  font-size: 20px;
  margin: 15px 0;
}

.collapse-full-tab-wrapper .btn {
  border: 1px solid #dfe3e8;
  height: 70px;
  width: 100%;

  &:hover {
    font-weight: bold;
  }
}

.card.card-body {
  border: 0;
  border-radius: 0;
}

.btn:focus {
  box-shadow: none;
}

.collapse-button {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 30px;

  &[aria-expanded="true"] {
    font-weight: bold;

    .s-icon {
      transform: rotate(180deg);
    }
  }
}

.s-icon {
  width: 10px;
}
</style>
