import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

export const routes = [
  {
    path: "/home",
    name: "HomeTest",
    component: Home,
  },
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Policies.vue"),
    meta: { title: "Stacklet - Policies" },
  },
  {
    path: "/admin",
    name: "Admin",
    component: () => import("../views/Admin.vue"),
    meta: { title: "Stacklet - Admin" },
  },
  {
    path: "/policies",
    name: "Policies",
    component: () => import("../views/Policies.vue"),
    meta: { title: "Stacklet - Policies" },
  },
  {
    path: "/policy/:policyName",
    name: "PolicyDetail",
    component: () => import("../views/PolicyDetail.vue"),
    meta: { title: "Stacklet - Policy Detail" },
  },
  {
    path: "/policy/:policyName/resource/:resourceKey",
    name: "ResourceDetail",
    component: () => import("../views/ResourceDetail.vue"),
    meta: { title: "Stacklet - Resource Detail" },
  },
  {
    path: "/policy/:policyName/execution/:executionID",
    name: "ExecutionDetail",
    component: () => import("../views/ExecutionDetail.vue"),
    meta: { title: "Stacklet - Execution Detail" },
  },
  {
    path: "/admin/:providerName",
    name: "ProviderDetail",
    component: () => import("../views/ProviderDetail.vue"),
    meta: { title: "Stacklet - Admin Detail" },
  },
  {
    path: "/:catchAll(.*)",
    component: () => import("../views/NotFound.vue"),
    meta: { title: "Stacklet - Yikes!" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

export default router;
