
import { computed, defineComponent, toRefs, watch, PropType } from "vue";

import ChartsContainer from "@/components/ChartsContainer.vue";
import Tag from "@/components/Tag.vue";
import {
  usePolicyMetrics,
  useFormatChartData,
} from "@/composables/usePolicyMetrics";
import {
  getFirstDateByPeriod,
  getIsoUtcDateTimeFormatted,
} from "@/utils/datetime";
import { formatPolicyName } from "@/utils/formatting";
import { MetricProperty } from "@/utils/enum";

import type { $TSFixMe, Policy } from "@/types";

export default defineComponent({
  name: "PolicyCard",
  components: {
    Tag,
    ChartsContainer,
  },
  props: {
    policy: {
      type: Object as PropType<Policy>,
      required: true,
    },
    propertySelected: {
      type: String,
      required: true,
    },
    period: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const { period } = toRefs(props);

    const policyName = computed(() => formatPolicyName(props.policy));

    const {
      policyResult,
      from,
      to,
      loading,
      error,
      fetchMore,
    } = usePolicyMetrics(props.policy, period.value);

    const policyMetricData = computed(() =>
      useFormatChartData(policyResult.value, props.propertySelected)
    );

    watch(period, async (period) => {
      try {
        await fetchMore({
          variables: {
            from: getIsoUtcDateTimeFormatted(getFirstDateByPeriod(period)),
            to,
          },
          updateQuery: (
            previousResult: $TSFixMe,
            { fetchMoreResult }: $TSFixMe
          ) => {
            if (!fetchMoreResult) return previousResult;
            return {
              ...previousResult,
              ...fetchMoreResult,
            };
          },
        });
      } catch (error) {
        if (error.name === "Invariant Violation") {
          // Swallowing Invariant Violation here to suppress errors when changing route
          // before `fetchMore` returns its Promise. Hopefully fixed in apollo client
          // at some point.
          // see: https://github.com/apollographql/apollo-client/issues/4114
        } else {
          throw error;
        }
      }
    });

    return {
      policyMetricData,
      policyName,
      from,
      loading,
      error,
      MetricProperty,
    };
  },
});
