<template>
  <div class="dropdown">
    <a
      id="dropdownMenuLink"
      class="btn dropdown-toggle"
      href="#"
      role="button"
      data-toggle="dropdown"
      aria-expanded="false"
    >
      <span class="st-icon st-icon-properties"></span>
      Properties
    </a>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
      <div class="dropdown-title text-muted">DISPLAY METRIC</div>
      <li>
        <div class="form-check">
          <label class="form-check-label" for="resourceCount">
            Resource Count
            <input
              id="resourceCount"
              v-model="state.property"
              class="form-check-input"
              type="radio"
              value="resourceCount"
              checked
              @change="handleSelect"
            />
          </label>
        </div>
      </li>
      <li>
        <div class="form-check">
          <label class="form-check-label" for="executionTime">
            Execution Time
            <input
              id="executionTime"
              v-model="state.property"
              class="form-check-input"
              type="radio"
              value="executionTime"
              @change="handleSelect"
            />
          </label>
        </div>
      </li>
      <li>
        <div class="form-check">
          <label class="form-check-label" for="apiCalls">
            API Calls
            <input
              id="apiCalls"
              v-model="state.property"
              class="form-check-input"
              type="radio"
              value="apiCalls"
              @change="handleSelect"
            />
          </label>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { reactive } from "vue";
export default {
  name: "PropertiesDropdown",
  emits: ["property-updated"],
  setup(props, context) {
    const state = reactive({
      property: "resourceCount",
    });
    const handleSelect = () => {
      context.emit("property-updated", state.property);
    };
    return {
      state,
      handleSelect,
    };
  },
};
</script>
<style lang="scss" scoped>
.btn {
  background-color: $white;
  border: 1px solid $black;
  color: $black;
  font-size: 16px;
  font-weight: normal;
  height: 40px;
}

.dropdown-toggle {
  align-items: center;
  display: flex;
}

.dropdown-menu {
  padding-left: 24px;
  width: 229px;

  .dropdown-title {
    font-size: 12px;
    margin-top: 15px;
  }
}

.form-check {
  margin-bottom: 15px;
  margin-top: 15px;
}

.st-icon-properties {
  height: 15px;
  margin-right: 8px;
  width: 15px;
}
</style>
