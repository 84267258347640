<template>
  <div class="chart-wrapper">
    <div v-if="loading" class="spinner-wrapper"><Spinner /></div>
    <div v-else-if="error" class="text-danger">Error fetching metrics</div>
    <div v-else>
      <div data-testid="chart-title">{{ chartTitle }}</div>
      <AreaChart
        :id="id"
        :metrics-api-data="metricData"
        :from-date="fromDate"
      />
    </div>
  </div>
</template>

<script>
import AreaChart from "@/components/AreaChart.vue";
import Spinner from "@/components/Spinner";

export default {
  name: "ChartsContainer",
  components: { AreaChart, Spinner },
  props: {
    metricData: {
      type: Array,
      required: true,
    },
    chartTitle: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    fromDate: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    error: {
      type: Error,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.chart-wrapper {
  align-items: center;
  display: flex;
  min-height: 207px;
  padding: 12px 0;
}

.spinner-wrapper {
  display: inline-block;
  margin: 0 auto;
}
</style>
